import { ArrowSmLeftIcon, ArrowSmRightIcon } from '@heroicons/react/solid';

import { addDays, isAfter, isBefore, parseISO, startOfDay, startOfToday } from 'date-fns';
import { observer } from 'mobx-react-lite';
import DatePicker from 'react-datepicker';
import { useQuery } from 'react-query';
import API from '~/app/api';
import { useStore } from '~/app/stores';

export default observer(function MarketDate() {
  const now = startOfToday();
  const { appStore } = useStore();

  const { data: minDate, isLoading } = useQuery<any>(['fetchMinDate'], async () =>
    startOfDay(parseISO(await API.Stocks.getMinDate()))
  );

  function change(newDate: Date) {
    appStore.date = newDate;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex w-56 py-1 px-4 bg-gradient-to-r text-white from-green-800 to-blue-600 rounded-full">
      <span className="font-bold mr-3 tracking-tighter">STOCK</span>
      <div className="flex items-center">
        <button
          disabled={!isAfter(appStore.date, minDate)}
          className="btn-date-nav"
          onClick={() => change(addDays(appStore.date, -1))}
        >
          <ArrowSmLeftIcon className="h-6 w-6"></ArrowSmLeftIcon>
        </button>
        <DatePicker
          className="bg-transparent w-[90px] focus:outline-none font-bold text-right"
          dateFormat="dd/MM/yyyy"
          minDate={new Date(minDate)}
          maxDate={new Date()}
          selected={appStore.date}
          onChange={change}
        />
        <button
          disabled={!isBefore(appStore.date, now)}
          className="btn-date-nav"
          onClick={() => change(addDays(appStore.date, 1))}
        >
          <ArrowSmRightIcon className="h-6 w-6"></ArrowSmRightIcon>
        </button>
      </div>
    </div>
  );
});
