import { FilterIcon } from '@heroicons/react/solid';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useStore } from '~/app/stores';

import MarketDate from './MarketDate';

export default observer(function NavBar() {
  // const { data: symbols, isLoading } = useQuery<string[]>(['fetchSymbols'], () => API.Stocks.getSymbols());
  const [isOpen, setIsOpen] = useState(false);
  const { appStore } = useStore();
  const symbols = appStore.symbols;

  useEffect(() => {
    appStore.loadSymbols();
  }, [appStore]);

  if (!symbols) {
    return <div>Loading...</div>;
  }
  if (!symbols.length) {
    return <div>No Symbols</div>;
  }

  const menu = symbols.map((symbol: any) => (
    <span
      className={classNames('px-1 hover:text-yellow-400 font-mono cursor-pointer', {
        'text-yellow-400 font-bold': appStore.symbol === symbol,
      })}
      key={symbol}
      onClick={() => (appStore.symbol = symbol)}
    >
      {symbol}
    </span>
  ));

  return (
    <nav>
      <div className="flex items-center">
        <MarketDate />
        <Select
          classNamePrefix="react-select"
          className="react-select-container"
          options={symbols.map((x) => ({ value: x, label: x }))}
          onChange={({ value }: any) => (appStore.symbol = value)}
        />
        <button
          className="bg-gray-800 h-8 rounded ml-1 px-1 focus:outline-none text-gray-500 hover:text-gray-300"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FilterIcon className="h-4 w-6"></FilterIcon>
        </button>
      </div>
      <div className={classNames('mt-3 flex flex-wrap', { hidden: !isOpen })}>
        {/* <ScrollMenu clickWhenDrag={false} data={menu}></ScrollMenu> */}
        {menu}
      </div>
    </nav>
  );
});
