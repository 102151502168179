import { makeAutoObservable } from 'mobx';

export default class BusyStore {
  busyRequestCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.busyRequestCount > 0;
  }

  busy() {
    this.busyRequestCount++;
  }

  idle() {
    if (this.busyRequestCount > 0) {
      this.busyRequestCount--;
    }
  }
}
