import { format } from 'date-fns';
import numeral from 'numeral';

export function formatDate(value: any) {
  return format(new Date(value), 'dd/MM/yyyy');
}

export function formatTime(value: any) {
  return format(new Date(value), 'HH:mm:ss');
}

export function formatNumber(value: any) {
  return numeral(value).format('0,0.00');
}

export function formatInt(value: any) {
  return numeral(value).format('0,0');
}
