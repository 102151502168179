import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import {
    Bar, ComposedChart, Label, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis,
    YAxis
} from 'recharts';
import API from '~/app/api';
import { useStore } from '~/app/stores';
import { formatInt, formatNumber } from '~/app/utils/utils';

const BarChartTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-black bg-opacity-75 rounded overflow-hidden text-sm font-bold text-gray-300 p-2">
        <div className="flex items-center">
          <div className="bg-blue-500 w-2 h-2 rounded-full m-2"></div>
          <div>{formatNumber(payload[0].payload.priceClose)}</div>
        </div>
        <div className="flex items-center">
          <div className="bg-yellow-400 w-2 h-2 rounded-full m-2"></div>
          <div>{formatInt(payload[2].value)}</div>
        </div>
        <div className="flex items-center">
          <div className="bg-red-600  w-2 h-2 rounded-full m-2"></div>
          <div>{formatInt(payload[1].value)}</div>
        </div>
        <div className="flex items-center">
          <div className="bg-green-500  w-2 h-2 rounded-full m-2"></div>
          <div>{formatInt(payload[0].value)}</div>
        </div>
      </div>
    );
  }

  return null;
};

const LineChartTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-blue-500 bg-opacity-75 px-2 rounded overflow-hidden text-sm font-bold text-gray-300">
        {formatNumber(payload[0].value)}
      </div>
    );
  }

  return null;
};

export default observer(function Chart() {
  const { appStore } = useStore();
  const { data, isLoading } = useQuery<any>(['fetchChart', appStore.symbol], () =>
    API.Stocks.getChart(appStore.symbol)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data.length) {
    return <div>Không có dữ liệu</div>;
  }

  var max = (_.last(data) as any)['priceClose'];
  return (
    <div style={{ height: '400px' }}>
      <ResponsiveContainer>
        <ComposedChart syncId="syncId" data={data}>
          <YAxis
            type="number"
            dataKey="priceClose"
            stroke="#38BDF8"
            domain={[(dataMin: any) => dataMin, (dataMax: any) => dataMax + 1]}
            tickSize={3}
            tickCount={10}
            orientation="right"
            tickFormatter={(tick) => formatNumber(tick)}
          ></YAxis>
          <Line type="monotone" strokeWidth={2} dataKey="priceClose" stroke="#38BDF8" dot={false} tooltipType="none" />
          <Bar dataKey="priceClose" fill="transparent" tooltipType="none" />
          <ReferenceLine
            y={max}
            label={<Label value={formatNumber(max)} fill="#38BDF8" position="right" />}
            stroke="#38BDF8"
            strokeDasharray="2"
          />
          <Tooltip content={<LineChartTooltip />} position={{ y: 0 }} />
        </ComposedChart>
      </ResponsiveContainer>
      <ResponsiveContainer>
        <ComposedChart syncId="syncId" data={data} style={{ marginTop: '-10px' }}>
          <XAxis dataKey="name" />
          <YAxis
            type="number"
            tickSize={3}
            orientation="right"
            tickFormatter={(tick) => formatNumber(Math.floor(tick / 1_000_000)) + 'M'}
          />
          <Bar dataKey="totalBuy" stackId="bar" fill="#16A34A" name="M" />
          <Bar dataKey="totalSell" stackId="bar" fill="#B91C1C" name="B" />
          <Bar dataKey="totalExchange" stackId="bar" fill="#FBBF24" name="M/B" />
          <Bar dataKey="priceClose" stackId="bar" fill="#38BDF8" name="P" />
          <Tooltip content={<BarChartTooltip />} position={{ y: -30 }} />
          <Legend />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
});
