import { startOfToday } from 'date-fns';
import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

import API from '../api';
import { StockParams } from '../models/stock.model';

export default class AppStore {
  symbol = '';
  date = startOfToday();
  symbols = null as string[] | null;
  isSaving = false;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getParams(): StockParams {
    return { symbol: this.symbol, date: this.date };
  }

  getSymbols() {
    return (this.symbols || [])?.join(' ');
  }

  async loadSymbols() {
    if (!this.symbols) {
      runInAction(() => {
        this.isLoading = true;
      });
      this.symbols = await API.Stocks.getSymbols();
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async updateSymbols(symbols: string) {
    runInAction(() => {
      this.isSaving = true;
    });
    await API.Stocks.updateSymbols(symbols);
    runInAction(() => {
      this.symbols = _.chain(symbols.split(' '))
        .map((x) => x.trim())
        .orderBy((x) => x)
        .value();
      this.isSaving = false;
    });
  }

  get hasSymbol() {
    return !!this.symbol;
  }
}
