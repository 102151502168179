import classNames from 'classnames';
import { Transaction } from '~/app/models/stock.model';
import { formatInt, formatNumber, formatTime } from '~/app/utils/utils';

type Props = {
  item: Transaction;
  index: number;
};

function priceClsx(item: Transaction) {
  return classNames('table-cell font-bold', {
    'text-yellow-400': item.pnl === 0,
    'text-green-500': item.pnl > 0,
    'text-red-500': item.pnl < 0,
  });
}

function rowClsx(index: number) {
  return classNames('bg-opacity-30 hover:bg-gray-700', {
    'bg-gray-800': index % 2 === 0,
    'bg-gray-600': index % 2 !== 0,
  });
}

export default function TxItem({ item, index }: Props) {
  return (
    <tr key={item.id} className={rowClsx(index)}>
      <td className="table-cell text-left text-gray-300">{formatTime(item.date)}</td>
      <td className={priceClsx(item)}>{formatNumber(item.price / 1000)}</td>
      <td className={priceClsx(item)}>{formatNumber(item.pnl / 1000)}</td>
      <td className="table-cell text-gray-300">{formatInt(item.volume)}</td>
      <td className="table-cell text-green-500">
        {item.side === 'B' && <span className="text-green-500">M</span>}
        {item.side === 'S' && <span className="text-red-500">B</span>}
      </td>
    </tr>
  );
}
