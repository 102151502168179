import 'react-datepicker/dist/react-datepicker.css';

import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BrowserRouter as Router, NavLink, Redirect, Route, Switch } from 'react-router-dom';

import { useStore } from './app/stores';
import Spinner from './components/Spinner';
import EditSymbols from './features/admin/EditSymbols';
import Login from './features/auth/Login';
import Home from './features/home/Home';

function App() {
  const { userStore } = useStore();

  useEffect(() => {
    userStore.getUser().then(() => {
      userStore.setAppLoaded(true);
    });
  }, [userStore]);

  function logout() {
    userStore.logout();
  }

  if (!userStore.appLoaded) {
    return <Spinner />;
  }

  return (
    <Router>
      <main>
        <div className="container mx-auto p-2">
          {userStore.isLoggedIn && (
            <div className="space-x-2 mb-3 font-bold">
              <NavLink
                to="/"
                exact
                className="mr-2 no-underline text-gray-500 hover:text-gray-200"
                activeClassName="text-gray-200"
              >
                Trang chủ
              </NavLink>
              {userStore.isAdmin && (
                <NavLink
                  to="/edit-symbols"
                  exact
                  className="no-underline text-gray-500 hover:text-gray-200"
                  activeClassName="text-gray-200 "
                >
                  Mã CP
                </NavLink>
              )}
              <button className="ml-4 font-bold text-gray-500 hover:text-gray-200" onClick={logout}>
                Đăng xuất
              </button>
            </div>
          )}
          <Switch>
            <PrivateRoute path="/" component={Home} exact></PrivateRoute>
            <PrivateRoute path="/edit-symbols" component={EditSymbols} exact></PrivateRoute>
            <Route path="/login">
              <Login />
            </Route>
            <Redirect from="/" to="/login" />
          </Switch>
        </div>
      </main>
    </Router>
  );
}

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  const { userStore } = useStore();
  return userStore.isLoggedIn ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/login" />
  );
};

export default observer(App);
