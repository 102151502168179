import _, { sortedUniqBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Stock, Transaction } from '~/app/models/stock.model';
import { formatDate, formatInt } from '~/app/utils/utils';

import TxItem from './TxItem';

type Props = {
  stock: Stock | undefined;
};

export default observer(function TxList({ stock }: Props) {
  const [desc, setDesc] = useState(false);

  if (!stock) {
    return <div>Không có giao dịch trong ngày đã chọn</div>;
  }

  function getTransactions() {
    let data = stock?.transactions || [];
    return desc ? _.orderBy(data, 'volume', 'desc') : data;
  }

  return (
    <>
      <div className="w-96">
        <div className="mb-2">
          <div className="font-bold text-yellow-400">
            {stock.symbol} <span className="float-right text-gray-400"> {formatDate(stock.createdOn)}</span>
          </div>
          <div>
            Tổng KL Khớp <span className="float-right font-bold">{formatInt(stock.totalVolume)}</span>
          </div>
          <div>
            KL Mua chủ động <span className="float-right font-bold text-green-500">{formatInt(stock.totalBuy)}</span>
          </div>
          <div>
            KL Bán chủ động <span className="float-right font-bold text-red-600">{formatInt(stock.totalSell)}</span>
          </div>
        </div>
        <div className="overflow-y-scroll scroller" style={{ maxHeight: 'calc(100vh - 200px)' }}>
          <table className="table-auto shadow-lg w-full text-right">
            <thead>
              <tr>
                <th className="table-cell text-left table-cell--sticky">Khớp</th>
                <th className="table-cell table-cell--sticky">Giá</th>
                <th className="table-cell table-cell--sticky">+/-</th>
                <th className="table-cell table-cell--sticky cursor-pointer" onClick={() => setDesc(!desc)}>
                  KL
                </th>
                <th className="table-cell table-cell--sticky">M/B</th>
              </tr>
            </thead>
            <tbody>
              {getTransactions().map((item: Transaction, index: number) => (
                <TxItem item={item} index={index} key={item.id} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});
