import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useStore } from '~/app/stores';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const { userStore } = useStore();
  const history = useHistory();

  function handleSubmit(e: any) {
    e.preventDefault();
    if (!username || !password) {
      return;
    }
    setShowError(false);
    userStore
      .login(username, password)
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        setShowError(true);
      });
  }

  return (
    <div className="max-w-sm mx-auto mt-40">
      <div className="flex-col justify-center items-center">
        <form
          onSubmit={handleSubmit}
          className="bg-gradient-to-b from-gray-500 to-gray-700 shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          {showError && <p className="text-red-900 rounded mb-3 text-center p-3 bg-red-300">Invalid credentials</p>}
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">Username / Email</label>
            <input
              value={username}
              onInput={(e: any) => setUsername(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Username / Email"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">Mật khẩu</label>
            <input
              value={password}
              onInput={(e: any) => setPassword(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="******************"
            />
          </div>
          <div className="text-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Đăng nhập
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
