import { makeAutoObservable, reaction } from 'mobx';

import API from '../api';
import { AuthUser } from '../models/auth-user.model';

export default class UserStore {
  user: AuthUser | null = null;
  appLoaded = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.user,
      (user) => {
        if (user) {
          window.localStorage.setItem('user', JSON.stringify(user));
        } else {
          window.localStorage.removeItem('user');
        }
      }
    );
  }

  get isLoggedIn() {
    return !!this.user;
  }

  login = async (username: string, password: string) => {
    try {
      const res = await API.Auth.login(username, password);
      this.setCurrentUser(res);
    } catch (error) {
      throw error;
    }
  };

  logout() {
    this.user = null;
  }

  async getUser() {
    const userProfile = localStorage.getItem('user') as string;
    if (!userProfile) {
      return;
    }

    try {
      const user = await API.Auth.current();
      this.setCurrentUser(user);
    } catch (error) {
      console.log(error);
    }
  }

  setAppLoaded(loaded: boolean) {
    this.appLoaded = loaded;
  }

  setCurrentUser(user: AuthUser) {
    user.roles = [];
    const roles = this.getDecodedToken(user.accessToken).role;
    Array.isArray(roles) ? (user.roles = roles) : user.roles.push(roles);
    this.user = user;
  }

  get isAdmin() {
    return this.user?.roles.includes('Host') || this.user?.roles.includes('Admin');
  }

  private getDecodedToken(token: string) {
    return JSON.parse(atob(token.split('.')[1]));
  }
}
