import React, { createContext } from 'react';

import AppStore from './app.store';
import BusyStore from './busy.store';
import UserStore from './user.store';

interface Store {
  busyStore: BusyStore;
  appStore: AppStore;
  userStore: UserStore;
}

export const store: Store = {
  busyStore: new BusyStore(),
  appStore: new AppStore(),
  userStore: new UserStore(),
};

export const StoreContext = createContext(store);
export function useStore() {
  return React.useContext(StoreContext);
}
