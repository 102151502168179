import { format } from 'date-fns';

import { Stock, StockParams } from '../models/stock.model';
import { http } from './http';

const stockAPI = {
  getList: (filter: StockParams): Promise<Stock | undefined> => {
    if (!filter.symbol) {
      return new Promise((resolve) => {
        return resolve(undefined);
      });
    }
    return http.get(`/stock?symbol=${filter.symbol}&date=${format(filter.date, 'yyyy-MM-dd')}`);
  },
  getSymbols: (): Promise<string[]> => {
    return http.get(`/stock/symbols`);
  },
  getMinDate: (): Promise<string> => {
    return http.get(`/stock/min-date`);
  },
  getChart: (symbol: any): Promise<any> => {
    return http.get(`/stock/chart?symbol=${symbol}`);
  },
  updateSymbols: (symbols: string): Promise<string[]> => {
    return http.post(`/stock/symbols`, { symbols: symbols });
  },
};
export default stockAPI;
