import { AuthUser } from '../models/auth-user.model';
import { http } from './http';

const authAPI = {
  login: (username: string, password: string): Promise<AuthUser> => {
    return http.post(`/auth/login`, { email: username, password });
  },
  current: (): Promise<AuthUser> => {
    return http.get(`/auth/account`);
  },
};
export default authAPI;
