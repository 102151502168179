import axios, { AxiosResponse } from 'axios';
import { store } from '~/app/stores';

import { AuthUser } from '../models/auth-user.model';

axios.defaults.baseURL = process.env.REACT_APP_API;

axios.interceptors.request.use((request) => {
  store.busyStore.busy();
  return request;
});

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('user');
    if (token) {
      let user = JSON.parse(token) as AuthUser;
      config.headers.Authorization = `Bearer ${user.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(async (res) => {
  try {
    return res;
  } catch (error) {
    console.log(error);
    return await Promise.reject(error);
  } finally {
    store.busyStore.idle();
  }
});

const responseBody = (res: AxiosResponse<any>) => res.data;
const http = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
};

export { http };
