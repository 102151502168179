import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStore } from '~/app/stores';

export default observer(function EditSymbols() {
  const { appStore } = useStore();
  const [value, setValue] = useState('');

  useEffect(() => {
    appStore.loadSymbols().then(() => {
      setValue(appStore.getSymbols());
    });
  }, [appStore]);

  function handleSubmit(e: any) {
    e.preventDefault();
    appStore.updateSymbols(value).then(() => {
      setValue(appStore.getSymbols());
    });
  }

  if (appStore.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="mb-3">Mã CP</h3>
      <textarea
        className="max-w-xl w-full h-48 bg-gray-800 text-white p-2 hover:outline-none rounded font-mono"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
      />
      <div>
        <button
          type="submit"
          disabled={appStore.isSaving}
          className="py-1 px-3 bg-green-700 hover:bg-green-600 rounded focus:outline-none"
        >
          {appStore.isSaving ? 'Đang lưu...' : 'Lưu'}
        </button>
      </div>
    </form>
  );
});
