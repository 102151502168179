import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import API from '~/app/api';
import { Stock } from '~/app/models/stock.model';
import { useStore } from '~/app/stores';
import Spinner from '~/components/Spinner';

import Chart from './Chart';
import Symbols from './Symbols';
import TxList from './TxList';

export default observer(function Home() {
  const { appStore } = useStore();

  const { data, isLoading } = useQuery<Stock | undefined>(
    ['fetchStock', appStore.getParams()],
    () => API.Stocks.getList(appStore.getParams()),
    {
      enabled: appStore.hasSymbol,
      cacheTime: 0,
    }
  );

  return (
    <>
      <Symbols />
      <div className="flex mt-3 space-x-4">
        <div className="relative w-96 flex-shrink-0 flex-grow-0">
          {isLoading && <Spinner />}
          {!isLoading && appStore.hasSymbol && <TxList stock={data} />}
        </div>
        <div className="flex-1">{appStore.hasSymbol && <Chart />}</div>
      </div>
    </>
  );
});
