import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';

import App from './App';
import { queryClient } from './app/configs/react-query.config';
import { store, StoreContext } from './app/stores';

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
